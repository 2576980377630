import Home from "../Pages/home-page/Home";

const homeRoutes = [
  {
    path: "/",
    element: <Home />
  },
];

export default homeRoutes;
