import React from 'react'
import AboutDirectorates from '../../Components/about-us-page-components/AboutDirectorates'

const AboutPageDirec = () => {
  return (
    <div>
      <AboutDirectorates/>
    </div>
  )
}

export default AboutPageDirec
