import React from 'react'
import PhotosGallery from '../../Components/gallery-page-components/PhotosGallery'
// import VideosGallery from '../../Components/gallery-page-components/VideosGallery'

const Gallery = () => {
  return (
    <div>
      <PhotosGallery/>
    </div>
  )
}

export default Gallery
