import React from 'react'
import ThousandCitiesExhibitin from '../../Components/exhibition-page-components/ThousandCitiesExhibitin'

const ExhibitionThousandCities = () => {
  return (
    <div>
      <ThousandCitiesExhibitin/>
    </div>
  )
}

export default ExhibitionThousandCities
