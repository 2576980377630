import React from 'react'
import IslamicEraExhibition from '../../Components/exhibition-page-components/IslamicEraExhibition'

const ExhibitionPage = () => {
  return (
    <div>
      <IslamicEraExhibition/>
    </div>
  )
}

export default ExhibitionPage
