import React from "react";
import RegulationsAndPolicies from "../../Components/about-us-page-components/RegulationsAndPolicies";

const AboutPageReg = () => {
  return (
    <>
      <RegulationsAndPolicies />
    </>
  );
};

export default AboutPageReg;
