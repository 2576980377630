import React from 'react'
import AynakCopperExhibition from '../../Components/exhibition-page-components/AynakCopperExhibition'

const ExhibitionAynakCopper = () => {
  return (
    <div>
      <AynakCopperExhibition/>
    </div>
  )
}

export default ExhibitionAynakCopper
