import React from 'react'
import CarExhibition from '../../Components/exhibition-page-components/CarExhibition'

const ExhibitionCar = () => {
  return (
    <div>
      <CarExhibition/>
    </div>
  )
}

export default ExhibitionCar
