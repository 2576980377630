import React from 'react'
import BronzeAgeExhibition from '../../Components/exhibition-page-components/BronzeAgeExhibition'

const ExhibitionBronzeAge = () => {
  return (
    <div>
      <BronzeAgeExhibition/>
    </div>
  )
}

export default ExhibitionBronzeAge
