import React from "react";
import AboutHistory from "../../Components/about-us-page-components/AboutHistory";

const AboutUs = () => {
  return (
    <>
    <AboutHistory/>
    </>
  );
};

export default AboutUs;
