import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaChevronDown, FaGlobe } from "react-icons/fa"; // Importing only the dropdown icon
// import logo from "../../src/images/logo/afghanInvest.png";
import mlogo from "../images/logo/mlogo.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";
import { FaX } from "react-icons/fa6";

const Navbar = () => {
  const [menu, setMenu] = useState(false);

  const handleChange = () => {
    setMenu(!menu)
  }

  const closeMenu = () => {
    setMenu(false)
  }
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "per",
      name: "فارسی",
      dir: "rtl",
      country_code: "ir",
    },
    {
      code: "ps",
      name: "پښتو",
      dir: "rtl",
      country_code: "pk",
    },
  ];

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
    if(cookies.get("i18next") === "ps" || cookies.get("i18next") === "per"){
      document.body.className = "persian-font";
    }
    else{
      document.body.className = "english-font";
    }
  }, [currentLanguage, t]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (code) => {
    i18next.changeLanguage(code);
    setDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-40">
        <div className="navbar bg-mstheme text-white flex items-center justify-between px-4 py-1">
          {/* Logo Section */}
          <div className="navbar-start flex items-center">
            <Link
              to="/"
              className="flex items-center text-xl font-bold hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
            >
              <img
                src={mlogo}
                alt="National Museum Afghanistan Logo"
                className="h-10 w-auto mr-2"
              />
            </Link>
          </div>

          {/* Menu Section for large screen */}
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal space-x-4">
              <li>
                <Link
                  to="/"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.first-menu")}
                </Link>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.second-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme rounded-box w-40 mt-1 z-20"
                >
                  <li>
                    <Link to='/about/history' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/directorates' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-2")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/technical-staff' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-3")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/regulations&policies' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-4")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.third-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme rounded-box w-52 mt-1 z-20"
                >
                  <li>
                    <Link to='/exhibition/islamic-era-exhibition' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/bronze-age-exhibition' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-2")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/aynak-copper-exhibition' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-3")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/ai-khanum' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-4")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/thousand-cities-exhibition' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-5")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/car-exhibition' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-6")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/events"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.fourth-menu")}
                </Link>
              </li>
              <li>
                <Link
                  to="/articles"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.fifth-menu")}
                </Link>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.sixth-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme rounded-box w-40 mt-1 z-20"
                >
                  <li>
                    <Link to='/gallery/photos' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.sixth-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='gallery/videos' className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.sixth-sub-menu-2")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.seventh-menu")}
                </Link>
              </li>
            </ul>
          </div>

          {/* Language Dropdown Section */}
          <div className="navbar-end flex items-center gap-2 relative">
            <div className="dropdown">
              <label
                tabIndex={0}
                className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                onClick={toggleDropdown} // Toggle dropdown on click
              >
                <FaGlobe className="mr-1" /> Language{" "}
                <FaChevronDown className="ml-1" />
              </label>
              {isDropdownOpen && (
                <ul
                  className="dropdown-content p-2 shadow-lg bg-mstheme rounded-box w-32 mt-1 z-20 max-h-60 overflow-auto"
                  onMouseLeave={() => setDropdownOpen(false)} // Close dropdown on mouse leave
                >
                  {languages.map(({ code, name, country_code }) => (
                    <li key={country_code}>
                      <a
                        href="#"
                        className={classNames(
                          "hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded",
                          {
                            disabled: currentLanguageCode === code,
                          }
                        )}
                        onClick={() => handleLanguageChange(code)} // Change language
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code} mx-2`}
                          style={{
                            opacity: currentLanguageCode === code ? 0.5 : 1,
                          }}
                        ></span>
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {/* mobile menu btn */}
          <div className='lg:hidden flex items-center'>
            {
              menu ? ( <FaX size={25} onClick={handleChange}/> ) : ( <FaBars size={25} onClick={handleChange}/> )
            }
          </div>
          <div className={` ${
            menu ? "translate-x-0" : "-translate-x-full"
          } lg:hidden flex flex-col absolute bg-black text-white left-0 top-16 font-semibold text-2xl text-center pt-8 pb-4 gap-8 w-full h-fit transition-transform duration-300`}>
            <ul className="menu menu-vertical space-y-4">
              <li>
                <Link
                  onClick={closeMenu}
                  to="/"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.first-menu")}
                </Link>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.second-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme rounded-box w-40 mt-1 z-20"
                >
                  <li>
                    <Link to='/about/history' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/directorates' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-2")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/technical-staff' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-3")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/regulations&policies' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.second-sub-menu-4")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.third-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme rounded-box w-52 mt-1 z-20"
                >
                  <li>
                    <Link to='/exhibition/islamic-era-exhibition' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/bronze-age-exhibition' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-2")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/aynak-copper-exhibition' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-3")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/ai-khanum' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-4")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/thousand-cities-exhibition' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-5")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/exhibition/car-exhibition' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.third-sub-menu-6")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  onClick={closeMenu}
                  to="/events"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.fourth-menu")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={closeMenu}
                  to="/articles"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.fifth-menu")}
                </Link>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-md font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.sixth-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme rounded-box w-40 mt-1 z-20"
                >
                  <li>
                    <Link to='/gallery/photos' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.sixth-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='gallery/videos' onClick={closeMenu} className="hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded">
                      {t("header.sixth-sub-menu-2")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  onClick={closeMenu}
                  to="/contact-us"
                  className="text-md font-medium hover:bg-mstheme_hover hover:text-white px-2 py-1 rounded"
                >
                  {t("header.seventh-menu")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
