import ContactPage from "../Pages/contact-us-page/ContactPage";


const contactusRoutes = [
  {
    path: "/contact-us",
    element: <ContactPage />
  },
];

export default contactusRoutes;
