import React from 'react'
import AiKhanumExhibition from '../../Components/exhibition-page-components/AiKhanumExhibition'

const ExhibitionAiKhanum = () => {
  return (
    <div>
      <AiKhanumExhibition/>
    </div>
  )
}

export default ExhibitionAiKhanum
